<template>
  <div class="uspa-entry">
    <main id="content" role="main" tabindex="-1">
      <div class="container">
        <div class="modal d-block interstitial bg-ltgray" id="interstitial-modal" role="dialog"
          aria-labelledby="myModalLabel" aria-hidden="false" data-backdrop="static" tabindex="-1">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-inner">
                <div class="modal-header">
                  <h5 class="modal-title">
                    You are now leaving the MSD Grant Portal
                  </h5>
                </div>
                <div class="modal-body">
                  <p>
                    You are now leaving this website. By continuing, you will be directed to a site intended only for
                    residents of the United States and Canada. We are called MSD everywhere, except
                    in the United States and Canada where we are known as Merck &amp; Co., Inc., Rahway, NJ, USA
                  </p>
                  <div class="modal-ctas">
                    <button class="btn-std" @click="closeTab">Cancel</button>
                    <router-link to="/" class="btn-std">Continue</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<style scoped>
  .bg-ltgray {
    background-color: #f7f7f7;
  }
</style>
<script>
  export default {
    methods: {
      closeTab() {
        window.close();
      },
    },
  };
</script>